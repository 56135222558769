<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\quizdictionaries\\edit.6293') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.code"
                    :label="$t('src\\views\\quizdictionaries\\edit.1660')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.name"
                    :label="$t('src\\views\\quizdictionaries\\edit.1009')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-row class="mx-0">
                <v-toolbar-title>{{ $t('src\\views\\quizdictionaries\\edit.5343') }}</v-toolbar-title>
            </v-row>
            <v-row class="mx-0">
                <template v-for="(value, index) in entity.values">
                    <v-col
                        :key="index"
                        cols="3">
                        <div
                            class="pa-6 mx-0 my-2"
                            style="border: 1px solid #00000061; border-radius: 4px;">
                            <v-row class="mx-0 px-0">
                                <v-col cols="6" class="pl-0">
                                    <v-text-field
                                        v-model="value.position"
                                        :label="$t('src\\views\\quizdictionaries\\edit.2906')"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" class="pr-0">
                                    <v-text-field
                                        v-model="value.code"
                                        :label="$t('src\\views\\quizdictionaries\\edit.3823')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="value.title"
                                :label="$t('src\\views\\quizdictionaries\\edit.1777')"
                                outlined
                            />
                            <v-text-field
                                v-model="value.translates.ru"
                                label="ru"
                                outlined
                            />
                            <v-text-field
                                v-model="value.translates.en"
                                label="en"
                                outlined
                            />
                            <v-text-field
                                v-model="value.translates.es"
                                label="es"
                                outlined
                            />
                            <v-text-field
                                v-model="value.translates.hi"
                                label="hi"
                                outlined
                            />
                            <v-text-field
                                v-model="value.translates.ko"
                                label="ko"
                                outlined
                            />
                            <v-btn
                                color="error"
                                @click="removeValue(index)">{{ $t('src\\views\\quizdictionaries\\edit.1671') }}</v-btn>
                        </div>
                    </v-col>
                </template>
            </v-row>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0 my-2">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="addValue">{{ $t('src\\views\\quizdictionaries\\edit.2005') }}</v-btn>
            </v-row>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\quizdictionaries\\edit.7692') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\quizdictionaries\\edit.4807') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'DictionariesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            valid: true
        }),
        computed: {
            ...mapState('quizDictionaries', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('quizDictionaries/get', { id: this.$route.params.id });
                for(let i = 0; i < this.entity.values.length; i++) {
                    if(!this.entity.values[i].translates) {
                        this.entity.values[i].translates = {
                            ru: '',
                            en: '',
                            es: '',
                            hi: '',
                            ko: ''
                        }
                    }
                }
            } else {
                await store.commit('quizDictionaries/CLEAR_ENTITY');
            }
        },
        methods: {
            async setPicture(index, file) {
                this.entity.values[index].picture = await store.dispatch('files/upload', { file });
            },
            addValue() {
                this.entity.values.push({
                    code: this.entity.values.length + 1,
                    title: '',
                    position: (this.entity.values.length * 10) + 10
                });
                this.sortValues();
            },
            removeValue(index) {
                this.entity.values.splice(index, 1);
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    this.sortValues();
                    await store.commit('quizDictionaries/SET_ENTITY', this.entity);
                    await store.dispatch('quizDictionaries/save');
                    await this.$router.push({ name: 'quiz-dictionaries-list' });
                }
            },
            sortValues() {
                this.entity.values = this.entity.values.sort((a, b) => { return a.position - b.position });
                for(let i = 0; i < this.entity.values.length; i++) {
                    this.entity.values[i].position = (i + 1) * 10;
                }
            },
            async remove() {
                await store.dispatch('quizDictionaries/delete', { id: this.entity._id });
                await this.$router.push({ name: 'quiz-dictionaries-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
